<script>
import TablePage from '../../../../components/table_page';

export default {
  extends: TablePage,
  components: {

  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('automatic_approval');
  },
  methods: {

  },
};
</script>
